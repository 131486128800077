import { useContext } from 'react';
import Image from '@next/image';

import { FilesContext } from '@providers/FilesProvider';
import { ConfigContext } from '@providers/ConfigProvider';
import ContentBox from '@components/atoms/ContentBox';
import Typography from '@components/atoms/Typography';
import Button from '@components/atoms/Button';
import PhoneNumber from '@components/atoms/PhoneNumber';
import { FormattedMessage } from 'utils/intl';
import createStyleVariables from 'utils/createStyleVariables';
import resolveImageUrl from 'utils/resolveImageUrl';

import classes from './EducationZoneArticle.module.scss';
import messages from './EducationZoneArticle.messages';

const EducationZoneArticle = () => {
  const { contact } = useContext(ConfigContext);
  const { getPageImageConfigs } = useContext(FilesContext);
  const [eventLogo, background, image] = getPageImageConfigs(['event-logo', 'article-background', 'article-image']);

  return (
    <ContentBox
      className={classes.container}
      style={createStyleVariables({ backgroundUrl: background ? `url(${resolveImageUrl(background)})` : undefined })}
    >
      <div className={classes.wrapper}>
        {eventLogo && <Image alt="background" resolveConfig={eventLogo} />}
        <div>
          <Typography variant="h1" className={classes.header}>
            <FormattedMessage
              {...messages.header}
              values={{
                highlight: (
                  <span className={classes.highlight}>
                    <FormattedMessage {...messages.highlight} />
                  </span>
                ),
              }}
            />
          </Typography>
          <Typography variant="h2">
            <FormattedMessage {...messages.description} />
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            color="secondary"
            href="/artykul/nie-wiesz-jak-zlozyc-wniosek-o-bon-dla-nauczyciela-sprawdz-nasz-poradnik"
          >
            <FormattedMessage {...messages.learnMore} />
          </Button>
          <Typography variant="body1" className={classes.phoneWrapper}>
            <FormattedMessage
              {...messages.phone}
              values={{
                phone: (
                  <PhoneNumber
                    tel={contact.landlinePhone}
                    phoneClassName={classes.phone}
                    variant="landline"
                    typographyVariant="body1"
                    weight="400"
                    withoutIcon
                  />
                ),
              }}
            />
          </Typography>
        </div>
      </div>
      {image && <Image alt="image" className={classes.image} resolveConfig={image} />}
    </ContentBox>
  );
};

export default EducationZoneArticle;
