import { defineMessages } from 'utils/intl';

export default defineMessages({
  header: 'Zrealizuj bon na {highlight}',
  highlight: 'laptop dla nauczyciela',
  description:
    'Postaw na nowoczesne narzędzia wspierające Twoją pracę. Bon na laptopa to doskonała okazja do poprawy efektywności i jakości edukacji.',
  learnMore: 'Dowiedz się więcej',
  phone: 'Masz pytania? Zadzwoń {phone}',
});
